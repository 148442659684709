<template>
  <div class="tgjcBox">
    <h2>体格检查</h2>
    <div class="infoBox">
      <div class="tgBox">
        <div class="text1">
          <span>{{ info.avBoyHeight }}</span>
          <span>男生平均 身高(CM)</span>
        </div>
        <div class="line"></div>
        <div class="text2">
          <span>{{ info.avGirlHeight }}</span>
          <span>女生平均 身高(CM)</span>
        </div>
      </div>
      <div class="tgBox">
        <div class="text1">
          <span>{{ info.avBoyWeight }}</span>
          <span>男生平均 体重(kg)</span>
        </div>
        <div class="text2">
          <span>{{ info.avGirlWeight }}</span>
          <span>女生平均 体重(kg)</span>
        </div>
      </div>
    </div>
    <img src="../../../assets/images/renti2.png" alt="" class="rt_img" />
    <div class="echartBox1" ref="chart">echartBox</div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      option: {
        xAxis: {
          type: "category",
          data: ["男生身高", "女生身高", "男生BMI", "女生BMI"],
          axisLabel: {
            margin: 10,
            color: "#fff",
            textStyle: {
              fontSize: 12,
            },
          },
          axisLine: {
            show: true, //是否显示轴线
            lineStyle: {
              color: "#028EAF", //刻度线的颜色
            },
          },
        },
        yAxis: {
          type: "value",
          width: 20,
          splitLine: {
            show: false,
          }, //去除网格线
          axisLine: {
            show: true, //是否显示轴线
            lineStyle: {
              color: "#028EAF", //刻度线的颜色
            },
          },
          axisLabel: {
            textStyle: {
              color: "#fff", // 设置Y轴标签文字的颜色为红色
            },
          },
        },
        series: [
          {
            data: [120, 200, 150, 80],
            type: "bar",
            barMaxWidth: 20,
            itemStyle: {
              color: function (params) {
                // params是一个包含数据项信息的对象
                var colorList = ["#1A7FFF", "#5C52E8", "#D83659", "#D16998"];
                return colorList[params.dataIndex % colorList.length];
              },
            },
          },
        ],
      },
    };
  },
  components: {},
  methods: {},
  mounted() {
    this.option.series[0].data = [
      this.info.boyGreatHeightCount,
      this.info.girlGreatHeightCount,
      this.info.boyGreatBmiCount || 0,
      this.info.girlGreatBmiCount || 0,
    ];
    this.myChart = echarts.init(this.$refs.chart);
    this.myChart.setOption(this.option, true);
  },
};
</script>

<style scoped lang="less">
.tgjcBox {
  width: 479px;
  height: 516px;
  background: url("../../../assets/images/tgjc_bg.png") no-repeat;
  background-size: cover;
  position: absolute;
  bottom: 18px;
  left: 18px;
  h2 {
    font-family: YouSheBiaoTiHei;
    font-weight: 400;
    font-size: 26px;
    color: #dffdfe;
    padding: 25px 30px;
  }
  .rt_img {
    position: absolute;
    right: 5px;
    top: 68px;
  }
  .infoBox {
    position: relative;
    margin-left: 17px;
    .tgBox {
      display: flex;
      width: 335px;
      height: 80px;
      background: url("../../../assets/images/height.png") no-repeat;
      padding-left: 70px;
      box-sizing: border-box;
      margin-top: 10px;
      .text1 {
        width: 100px;
        text-align: center;
        padding-top: 5px;
        span {
          font-size: 24px;
          color: #73d3ff;
          display: block;
          &:last-child {
            font-size: 11px;
            color: #fff;
            width: 54px;
            margin: 0 auto;
          }
        }
      }
      .line {
        width: 1px;
        height: 20px;
        background-color: #1b4e7b;
        margin-top: 30px;
      }
      .text2 {
        width: 100px;
        text-align: center;
        padding-top: 5px;
        span {
          font-size: 24px;
          color: #f2536d;
          display: block;
          &:last-child {
            font-size: 11px;
            color: #fff;
            width: 54px;
            margin: 0 auto;
          }
        }
      }
    }
  }
  .echartBox1 {
    width: 100%;
    height: 250px;
    margin-top: 0;
  }
}
</style>
