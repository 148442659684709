<template>
  <div class="mainBox">
    <div class="chartBox" ref="chart"></div>
    <div class="mainImg">
      <div class="text">基本指数</div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      indicator: [
        {
          name: "农资占比1",
          max: 5000,
        },
        {
          name: "农资占比2",
          max: 5000,
        },
        {
          name: "农资占比3",
          max: 5000,
        },
        {
          name: "农资占比4",
          max: 5000,
        },
        {
          name: "农资占比5",
          max: 5000,
        },
        {
          name: "农资占比6",
          max: 5000,
        },
        {
          name: "农资占比6",
          max: 5000,
        },
      ],
      option: {
        color: ["#rgba(74, 153, 255,1)", "rgba(75, 255, 252,1)"],

        tooltip: {
          trigger: "item",
          borderColor: "#62FFFF",
          // formatter: ' {b}     {c}',
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          show: false,
          orient: "vertical",
          icon: "circle", //图例形状
          data: ["动保投入", "农资占比2"],
          bottom: "5%",
          right: "5%",
          itemWidth: 14, // 图例标记的图形宽度。[ default: 25 ]
          itemHeight: 14, // 图例标记的图形高度。[ default: 14 ]
          itemGap: 21, // 图例每项之间的间隔。[ default: 10 ]横向布局时为水平间隔，纵向布局时为纵向间隔。
          textStyle: {
            fontSize: 14,
            color: "#00E4FF",
          },
        },
        radar: {
          // shape: 'circle',
          // 调整雷达图的位置
          center: ["50%", "50%"], // 雷达图的中心点，默认在容器的中心
          radius: "70%", // 雷达图的半径，默认占容器大小的 70%
          axisName: {
            color: "#2898E5",
            fontSize: 10,
          },
          axisNameGap: 5, // 值越小，文字越靠近雷达图
          indicator: [],
          splitArea: {
            // 坐标轴在 grid 区域中的分隔区域，默认不显示。
            show: false, // 关闭背景填充区域
            areaStyle: {
              // 分隔区域的样式设置。
              color: "#5F6568",
              // color: ['rgba(255,255,255,0)', 'rgba(255,255,255,0)'] // 分隔区域颜色。分隔区域会按数组中颜色的顺序依次循环设置颜色。默认是一个深浅的间隔色。
            },
          },
          axisLine: {
            //指向外圈文本的分隔线样式
            show: true,
            lineStyle: {
              color: "#1D6B86",
            },
          },
          splitLine: {
            show: true, // 关闭背景网格线
            lineStyle: {
              color: "#1D6B86", // 分隔线颜色
              width: 1, // 分隔线线宽
            },
          },
        },
        series: [
          {
            type: "radar",
            symbolSize: 4,
            // symbol: 'angle',
            data: [
              {
                value: [],
                name: "动保投入2",
                lineStyle: {
                  width: 1, // 这里设置线条的宽度，比如设置为1使其变细
                },
                itemStyle: {
                  lineStyle: {
                    color: "rgba(75, 255, 252,1)",
                    shadowColor: "rgba(75, 255, 252,1)",
                    shadowBlur: 10,
                  },
                  shadowColor: "rgba(75, 255, 252,1)",
                  shadowBlur: 10,
                },
                areaStyle: {
                  // 单项区域填充样式
                  color: {
                    type: "linear",
                    x: 0, //右
                    y: 0, //下
                    x2: 1, //左
                    y2: 1, //上
                    colorStops: [
                      {
                        offset: 0,
                        color: "rgba(75, 255, 252,1)",
                      },
                      {
                        offset: 0.5,
                        // color: 'rgba(0,0,0,0)'
                        color: "rgba(75, 255, 252,0.1)",
                      },
                      {
                        offset: 1,
                        color: "rgba(75, 255, 252,1)",
                      },
                    ],
                    globalCoord: false,
                  },
                  opacity: 1, // 区域透明度
                },
              },
            ],
          },
        ],
      },
    };
  },
  components: {},
  methods: {},
  mounted() {
    
    this.indicator = [
      {
        name: "身高良好",
        max: this.info.totalUserCount,
      },
      {
        name: "体重良好",
        max: this.info.totalUserCount,
      },
      {
        name: "bmi良好",
        max: this.info.totalUserCount,
      },
      {
        name: "足踝正常",
        max: this.info.totalUserCount,
      },
      {
        name: "体态良好",
        max: this.info.totalUserCount,
      },
      {
        name: "口腔良好",
        max: this.info.totalUserCount,
      },
      {
        name: "视力良好",
        max: this.info.totalUserCount,
      },
    ];
    this.option.series[0].data[0].value = [
      this.info.boyGreatHeightCount + this.info.girlGreatHeightCount,
      this.info.girlWeightGreatCount + this.info.boyWeightGreatCount,
      this.info.boyGreatBmiCount + this.info.girlGreatBmiCount,
      this.info.boyZuHuaiGreatCount + this.info.girlZuHuaiGreatCount,
      this.info.boyTiTaiOkCount + this.info.girlTiTaiOkCount,
      this.info.teethOkCount,
      this.info.eyeOkCount || 0,
    ];
    // this.option.series[0].data[0].value = [30,30,30,30,30,30,30]
    this.option.radar.indicator = this.indicator;
    this.myChart = echarts.init(this.$refs.chart);
    this.myChart.setOption(this.option, true);
  },
};
</script>

<style scoped lang="less">
.mainBox {
  width: 896px;
  height: 650px;
  position: absolute;
  top: 94px;
  left: 513px;
  .chartBox {
    width: 100%;
    height: 400px;
  }
  .mainImg {
    width: 398px;
    height: 280px;
    background: url("../../../assets/images/dz.png") no-repeat;
    background-size: cover;
    position: absolute;
    bottom: 25px;
    left: 50%;
    margin-left: -199px;
    .text {
      color: #fff;
      text-align: center;
      font-size: 30px;
      margin-top: 100px;
    }
  }
}
</style>
