<template>
  <div class="eyeBox">
    <h2>视力检查</h2>
    <div class="eyeContent">
      <div class="box">
        <div class="bfb">
          <span>{{ info.boyEyeGreatPersent * 100 }}</span
          >%
        </div>
        <div class="text">男生裸眼合格率</div>
      </div>
      <div class="box box2">
        <div class="bfb">
          <span>{{ info.girlEyeGreatPersent * 100 }}</span
          >%
        </div>
        <div class="text">女生裸眼合格率</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  components: {},
  methods: {},
  mounted() {},
};
</script>

<style scoped lang="less">
.eyeBox {
  width: 439px;
  height: 306px;
  background: url("../../../assets/images/kqjc_bg.png") no-repeat;
  background-size: cover;
  position: absolute;
  bottom: 18px;
  left: 970px;
  h2 {
    font-family: YouSheBiaoTiHei;
    font-weight: 400;
    font-size: 26px;
    color: #dffdfe;
    padding: 25px 30px 15px;
  }
  .eyeContent {
    display: flex;
    justify-content: space-between;
    padding: 0 35px;
    .box {
      width: 174px;
      height: 190px;
      background: url("../../../assets/images/boyBox.png") no-repeat;
      padding-top: 20px;
      box-sizing: border-box;
      .bfb {
        color: #fff;
        text-align: center;
        font-weight: normal;
        font-size: 16px;
        span {
          font-size: 36px;
          font-weight: bold;
        }
      }
      .text {
        font-size: 16px;
        color: #fff;
        margin-top: 10px;
        text-align: center;
      }
    }
    .box2 {
      background: url("../../../assets/images/girlBox.png") no-repeat;
    }
  }
}
</style>
