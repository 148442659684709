var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tjsjBox"},[_c('h2',[_vm._v("统计数据")]),_c('div',{staticClass:"addressBox"},[_c('img',{attrs:{"src":require("@/assets/images/dw.png"),"alt":""}}),_c('span',[_vm._v(_vm._s(_vm.info.province)+_vm._s(_vm.info.city)+_vm._s(_vm.info.area))])]),_c('div',{staticClass:"schoolBox"},[_c('div',{staticClass:"box"},[_c('span',[_vm._v("学校")]),_c('em',[_vm._v(_vm._s(_vm.info.school))])]),_c('div',{staticClass:"box"},[_c('span',[_vm._v("年级班号")]),_c('em',[_vm._v(_vm._s(_vm.info.classLevel)+_vm._s(_vm.info.classRoom))])])]),_c('div',{staticClass:"tjBox"},[_c('div',{staticClass:"tjLine"},[_c('img',{attrs:{"src":require("@/assets/images/people.png"),"alt":""}}),_c('div',{staticClass:"progressBox"},[_c('h3',[_vm._v("总统计人数："+_vm._s(_vm.info.totalUserCount))]),_c('div',{staticClass:"progress"},[_c('span',{style:('width: ' +
              (
                (_vm.info.girlAllOkCount + _vm.info.boyAllOkCount) /
                _vm.info.totalUserCount
              ).toFixed(0) +
              '%')})])]),_c('div',{staticClass:"peopleInfo"},[_c('div',{staticClass:"bfb"},[_vm._v(" "+_vm._s(( (_vm.info.girlAllOkCount + _vm.info.boyAllOkCount) / _vm.info.totalUserCount ).toFixed(0))+"% ")]),_c('div',{staticClass:"num"},[_vm._v(_vm._s(_vm.info.girlAllOkCount + _vm.info.boyAllOkCount))]),_c('div',{staticClass:"text"},[_vm._v("合格人数")])])]),_c('div',{staticClass:"tjLine"},[_c('img',{attrs:{"src":require("@/assets/images/boyNum.png"),"alt":""}}),_c('div',{staticClass:"progressBox"},[_c('h3',[_vm._v("男生人数："+_vm._s(_vm.info.boyCount))]),_c('div',{staticClass:"progress"},[_c('span',{style:('width: ' +
              ((_vm.info.boyAllOkCount / _vm.info.boyCount) * 100).toFixed(0) +
              '%')})])]),_c('div',{staticClass:"peopleInfo"},[_c('div',{staticClass:"bfb"},[_vm._v(" "+_vm._s(((_vm.info.boyAllOkCount / _vm.info.boyCount) * 100).toFixed(0))+"% ")]),_c('div',{staticClass:"num"},[_vm._v(_vm._s(_vm.info.boyAllOkCount))]),_c('div',{staticClass:"text"},[_vm._v("合格人数")])])]),_c('div',{staticClass:"tjLine"},[_c('img',{attrs:{"src":require("@/assets/images/girlNum.png"),"alt":""}}),_c('div',{staticClass:"progressBox"},[_c('h3',[_vm._v("女生人数："+_vm._s(_vm.info.girlCount))]),_c('div',{staticClass:"progress"},[_c('span',{style:('width: ' +
              ((_vm.info.girlAllOkCount / _vm.info.girlCount) * 100).toFixed(0) +
              '%')})])]),_c('div',{staticClass:"peopleInfo"},[_c('div',{staticClass:"bfb"},[_vm._v(" "+_vm._s(((_vm.info.girlAllOkCount / _vm.info.girlCount) * 100).toFixed(0))+"% ")]),_c('div',{staticClass:"num"},[_vm._v(_vm._s(_vm.info.girlAllOkCount))]),_c('div',{staticClass:"text"},[_vm._v("合格人数")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }