<template>
  <div class="kqjcBox">
    <h2>口腔检查</h2>
    <div class="teeBox">
      <img src="@/assets/images/tee.png" alt="" />
      <div class="teeInfo">
        <div class="txt1">口腔内牙齿总数</div>
        <div class="txt2">
          <span>{{ info.avTeethCount }}</span
          >颗
        </div>
      </div>
      <img src="@/assets/images/tee2.png" alt="" />
    </div>
    <div class="proBox">
      <h3>龋齿占比</h3>
      <div class="probb">
        <div class="box">
          <div
            class="procssBox"
            :style="'width: ' + info.youChiPercent + '%'"
          ></div>
        </div>
        <em>{{ info.youChiPercent }}%</em>
      </div>
    </div>

    <div class="proBox">
      <h3>牙列不齐占比</h3>
      <div class="probb">
        <div class="box">
          <div
            class="procssBox"
            :style="'width: ' + info.yaLieBuQiPercent + '%'"
          ></div>
        </div>
        <em>{{ info.yaLieBuQiPercent }}%</em>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  components: {},
  methods: {},
  mounted() {},
};
</script>

<style scoped lang="less">
.kqjcBox {
  width: 439px;
  height: 306px;
  background: url("../../../assets/images/kqjc_bg.png") no-repeat;
  background-size: cover;
  position: absolute;
  bottom: 18px;
  left: 513px;
  h2 {
    font-family: YouSheBiaoTiHei;
    font-weight: 400;
    font-size: 26px;
    color: #dffdfe;
    padding: 25px 30px 15px;
  }
  .teeBox {
    display: flex;
    padding: 0 20px;
    .teeInfo {
      flex: 1;
      margin-left: 18px;
      padding-top: 20px;
      .txt1 {
        color: #cde3f6;
        font-size: 14px;
      }
      .txt2 {
        color: #f4fdff;
        span {
          font-size: 20px;
          font-style: italic;
          font-weight: bold;
          margin-right: 5px;
        }
      }
    }
  }
  .proBox {
    padding: 0 20px;
    margin-top: 10px;
    h3 {
      color: #fff;
      font-size: 14px;
    }
    .probb {
      display: flex;
      align-items: center;
      .box {
        height: 20px;
        border: 1px solid #2d6b80;
        border-radius: 10px;
        margin-top: 3px;
        flex: 1;
        .procssBox {
          height: 10px;
          background-image: linear-gradient(to right, #024aab, #02cbc3);
          margin: 4px 4px 0;
          border-radius: 10px;
        }
      }
      em {
        color: #fff;
        margin-left: 5px;
      }
    }
  }
}
</style>
