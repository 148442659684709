<template>
  <div class="zhjcBox">
    <h2>足踝检查</h2>
    <div class="zhjcContent">
      <img src="@/assets/images/foot.png" alt="" class="img" />
      <div class="tabBox">
        <div :class="sex === 0 ? 'tab on' : 'tab'" @click="changeSex(0)">
          男生
        </div>
        <div :class="sex === 1 ? 'tab on' : 'tab'" @click="changeSex(1)">
          女生
        </div>
      </div>
      <div class="infoBox">
        <div class="echartBox" ref="chart"></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      datas: [],
      option: {
        color: [
          "#F56463",
          "#00C6FF",
          "#F09615",
          "#0079E6",
          "#a814ff",
          "#03b915",
        ],
        grid: {
          // top,bottom,left,right等
          left: 'center',
          top: "0",
          containLabel: true,
        },
        title: {
          // 圆环标题
          text: "体格占比", // 主标题
          textStyle: {
            // 主标题样式
            color: "#fff",
            fontWeight: "bold",
            fontSize: 20,
          },
          left: "34%", // 定位到适合的位置
          top: "46%", // 定位到适合的位置
          subtext: ``, // 副标题
          subtextStyle: {
            // 副标题样式
            color: "#fff",
            fontSize: 24,
            fontWeight: "bold",
          },
          textAlign: "center", // 主、副标题水平居中显示
        },
        legend: {
          itemHeight: 14,
          itemWidth: 14,
          icon: "rect",
          orient: "horizontal",
          x: "center",
          y: "bottom",
          textStyle: {
            align: "left",
            color: "#",
            verticalAlign: "middle",
            rich: {
              name: {
                width: 80,
                fontSize: 16,
              },
              value: {
                width: 20,
                align: "right",
                fontFamily: "Medium",
                fontSize: 16,
              },
              rate: {
                width: 10,
                align: "right",
                fontSize: 16,
              },
            },
          },
          data: [],
        },
        tooltip: {
          trigger: "item",
          formatter: "{d}%",
        },
        series: [
          {
            name: "",
            type: "pie",
            radius: ["30%", "60%"],
            center: ["35%", "50%"],
            roseType: "radius",
            label: {
              formatter: "{d}%",
            },
            labelLine: {
              length: 1,
              length2: 20,
            },
            data: [],
          },
        ],
      },
      sex: 1, //0男生1女生
    };
  },
  components: {},
  methods: {
    changeSex(sex) {
      this.sex = sex;
      if (sex === 0) {
        this.datas = [
          { value: this.info.boyZuHuaiGreatCount || 0, name: "正常" },
          { value: this.info.boyBianPingZuCount || 0, name: "扁平足" },
          { value: this.info.boyGaoGongZuCount || 0, name: "高足弓" },
          { value: this.info.boyOTuiCount || 0, name: "O型腿" },
          { value: this.info.boyXTuiCount || 0, name: "X型腿" },
          { value: this.info.boyZuWaiFanCount || 0, name: "足外翻" },
          { value: this.info.boyNeiWaiBaCount || 0, name: "内外八" },
        ];
      } else {
        this.datas = [
          { value: this.info.girlZuHuaiGreatCount || 0, name: "正常" },
          { value: this.info.girlBianPingZuCount || 0, name: "扁平足" },
          { value: this.info.girlGaoGongZuCount || 0, name: "高足弓" },
          { value: this.info.girlOTuiCount || 0, name: "O型腿" },
          { value: this.info.girlXTuiCount || 0, name: "X型腿" },
          { value: this.info.girlZuWaiFanCount || 0, name: "足外翻" },
          { value: this.info.girlNeiWaiBaCount || 0, name: "内外八" },
        ];
      }
      this.option.series[0].data = this.datas;
      this.option.legend.data = this.datas;
      this.myChart.setOption(this.option, true);
    },
  },
  created() {},
  mounted() {
    //体态检查
    this.$nextTick(() => {
      this.myChart = echarts.init(this.$refs.chart);
      this.changeSex(0);
    });
  },
};
</script>

<style scoped lang="less">
.zhjcBox {
  width: 479px;
  height: 430px;
  background: url("../../../assets/images/zhjc_bg.png") no-repeat;
  background-size: cover;
  position: absolute;
  top: 94px;
  right: 18px;
  .echartBox {
    width: 100%;
    height: 240px;
    margin: -30px auto 0;
  }
  .infoBox {
    position: relative;
    padding-left: 20px;
  }
  h2 {
    font-family: YouSheBiaoTiHei;
    font-weight: 400;
    font-size: 26px;
    color: #dffdfe;
    padding: 25px 30px 15px;
  }
  .zhjcContent {
    position: relative;
    .img {
      position: absolute;
      right: 8px;
      top: 17px;
    }
    .tabBox {
      margin: 30px 38px;
      display: flex;
      .tab {
        width: 44px;
        height: 34px;
        border-bottom: 3px solid transparent;
        font-size: 16px;
        color: #667081;
        text-align: center;
        margin-right: 40px;
        cursor: pointer;
      }
      .on {
        font-size: 18px;
        border-bottom: 3px solid #38ffc0;
        color: #fff;
      }
    }
  }
}
</style>
