<template>
  <div class="tjsjBox">
    <h2>统计数据</h2>
    <div class="addressBox">
      <img src="@/assets/images/dw.png" alt="" />
      <span>{{ info.province }}{{ info.city }}{{ info.area }}</span>
    </div>
    <div class="schoolBox">
      <div class="box">
        <span>学校</span>
        <em>{{ info.school }}</em>
      </div>
      <div class="box">
        <span>年级班号</span>
        <em>{{ info.classLevel }}{{ info.classRoom }}</em>
      </div>
    </div>
    <div class="tjBox">
      <div class="tjLine">
        <img src="@/assets/images/people.png" alt="" />
        <div class="progressBox">
          <h3>总统计人数：{{ info.totalUserCount }}</h3>
          <div class="progress">
            <span
              :style="
                'width: ' +
                (
                  (info.girlAllOkCount + info.boyAllOkCount) /
                  info.totalUserCount
                ).toFixed(0) +
                '%'
              "
            ></span>
          </div>
        </div>
        <div class="peopleInfo">
          <div class="bfb">
            {{
              (
                (info.girlAllOkCount + info.boyAllOkCount) /
                info.totalUserCount
              ).toFixed(0)
            }}%
          </div>
          <div class="num">{{ info.girlAllOkCount + info.boyAllOkCount }}</div>
          <div class="text">合格人数</div>
        </div>
      </div>
      <div class="tjLine">
        <img src="@/assets/images/boyNum.png" alt="" />
        <div class="progressBox">
          <h3>男生人数：{{ info.boyCount }}</h3>
          <div class="progress">
            <span
              :style="
                'width: ' +
                ((info.boyAllOkCount / info.boyCount) * 100).toFixed(0) +
                '%'
              "
            ></span>
          </div>
        </div>
        <div class="peopleInfo">
          <div class="bfb">
            {{ ((info.boyAllOkCount / info.boyCount) * 100).toFixed(0) }}%
          </div>
          <div class="num">{{ info.boyAllOkCount }}</div>
          <div class="text">合格人数</div>
        </div>
      </div>
      <div class="tjLine">
        <img src="@/assets/images/girlNum.png" alt="" />
        <div class="progressBox">
          <h3>女生人数：{{ info.girlCount }}</h3>
          <div class="progress">
            <span
              :style="
                'width: ' +
                ((info.girlAllOkCount / info.girlCount) * 100).toFixed(0) +
                '%'
              "
            ></span>
          </div>
        </div>
        <div class="peopleInfo">
          <div class="bfb">
            {{ ((info.girlAllOkCount / info.girlCount) * 100).toFixed(0) }}%
          </div>
          <div class="num">{{ info.girlAllOkCount }}</div>
          <div class="text">合格人数</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  components: {},
  methods: {},
  mounted() {},
};
</script>

<style scoped lang="less">
.tjsjBox {
  width: 479px;
  height: 430px;
  background: url("../../../assets/images/tjsj_bg.png") no-repeat;
  background-size: cover;
  position: absolute;
  top: 94px;
  left: 18px;

  h2 {
    font-family: YouSheBiaoTiHei;
    font-weight: 400;
    font-size: 26px;
    color: #dffdfe;
    padding: 25px 30px;
  }
  .addressBox {
    color: #38ffc0;
    display: flex;
    align-items: center;
    font-weight: bold;
    padding: 0 25px;
    img {
      width: 30px;
      display: inline-block;
    }
    span {
      font-size: 20px;
      font-style: italic;
    }
  }
  .schoolBox {
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 25px;
    color: #fff;
    .box {
      color: #cde3f6;
      &:last-child {
        margin-left: 40px;
      }
      span {
        margin-right: 20px;
        font-size: 12px;
      }
      em {
        color: #62a5ff;
      }
    }
  }
  .tjBox {
    padding-right: 20px;
    .tjLine {
      display: flex;
      align-items: center;
      margin-top: 12px;
      color: #fff;
      img {
        width: 60px;
        margin: 0 20px;
      }
      .progressBox {
        flex: 1;
        h3 {
          font-size: 16px;
          font-weight: normal;
        }
        .progress {
          height: 5px;
          background: #0e2639;
          margin-top: 12px;
          position: relative;
          span {
            background-image: linear-gradient(to right, #0e2c3a, #18cc5e);
            display: block;
            position: absolute;
            height: 5px;
          }
        }
      }
      .peopleInfo {
        margin-left: 12px;
        text-align: right;
        .bfb {
          font-size: 20px;
        }
        .num {
          font-size: 14px;
        }
        .text {
          font-size: 12px;
          color: rgba(255, 255, 255, 0.3);
        }
      }
    }
  }
  .tgjcBox {
  }
}
</style>
