<template>
  <div class="databoard">
    <div class="titleBox">
      <div class="timeBox">
        <span>{{ time.formattedTime }}</span>
        <em>{{ time.formattedDate }}</em>
        <i>{{ time.weekDay }}</i>
      </div>
      <div class="title">生长发育数据看板</div>
    </div>
    <div class="content">
      <!-- 统计数据 -->
      <tjsjBox v-if="info" :info="info"></tjsjBox>
      <!-- 体格检查 -->
      <tgjcBox v-if="info" :info="info"></tgjcBox>
      <!-- 口腔检查 -->
      <kqjcBox v-if="info" :info="info"></kqjcBox>
      <!-- 视力检查 -->
      <eyeBox v-if="info" :info="info"></eyeBox>
      <!-- 体态检查 -->
      <ttjcBox v-if="info" :info="info"></ttjcBox>
      <!-- 足踝检查 -->
      <zhjcBox v-if="info" :info="info"></zhjcBox>
      <!-- 基本指数 -->
      <mainBox v-if="info" :info="info"></mainBox>
    </div>
  </div>
</template>

<script>
import tjsjBox from "./components/tjsjBox";
import tgjcBox from "./components/tgjcBox";
import kqjcBox from "./components/kqjcBox";
import eyeBox from "./components/eyeBox";
import ttjcBox from "./components/ttjcBox";
import zhjcBox from "./components/zhjcBox";
import mainBox from "./components/mainBox";

export default {
  components: {
    tjsjBox,
    tgjcBox,
    kqjcBox,
    eyeBox,
    ttjcBox,
    zhjcBox,
    mainBox
  },
  data() {
    return {
      info: "",
      time: {
        formattedDate: "",
        formattedTime: "",
        weekDay: "",
      },
    };
  },
  methods: {
    getTime() {
      const now = new Date();
      const year = now.getFullYear();
      const month = now.getMonth() + 1; // 月份从0开始
      const day = now.getDate();
      const hours = now.getHours();
      const minutes = now.getMinutes();
      const seconds = now.getSeconds();
      const weekdays = [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ];
      const weekDay = weekdays[now.getDay()];

      const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
        .toString()
        .padStart(2, "0")}`;
      const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

      this.time.formattedDate = formattedDate;
      this.time.formattedTime = formattedTime;
      this.time.weekDay = weekDay;
    },
  },
  mounted() {
    this.getAjax(
      "api/admin/admin/bigscreen/user/stat",
      {
        toPageNum: 0,
        pageSize: 0,
        nickName: "",
        phone: "",
      },
      "post"
    ).then((res) => {
      this.info = res.data;
    });

   
    setInterval(() => {
      this.getTime();
    }, 1000);
  },
};
</script>
<style lang="less" scoped>
.databoard {
  width: 100%;
  height: 100%;
  background: url("../../assets/images/bg.png") no-repeat;
  background-size: cover;
  position: relative;

  .titleBox {
    width: 100%;
    height: 100px;
    box-sizing: border-box;
    background: url("../../assets/images/title_bg.png") no-repeat;
    background-size: cover;
    display: flex;
    padding: 7px 54px 0;
    position: relative;
    .timeBox {
      color: #a3c4ff;
      position: absolute;
      span {
        font-size: 22px;
        margin-right: 20px;
      }
      em {
        color: #5676ae;
        font-size: 16px;
        margin-right: 20px;
      }
      i {
        color: #5676ae;
        font-size: 16px;
        font-style: normal;
      }
    }
    .title {
      font-family: YouSheBiaoTiHei;
      font-weight: 400;
      font-size: 50px;
      color: #333333;
      background: #333;
      text-align: center;
      margin: 0 auto;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-shadow: 5px -4px #63dddc;
    }
  }
  
}
</style>
